<template>
<div> 
  <div class="form-group col-md-4" :class="[data.is_required == true ? 'required' : '']">
    <label class="control-label">{{data.attribute_name}}</label>
    <select class="form-control" v-model="data.userinput" >
      <option v-for="(item, index) in data.values" :key="index" :value="item._id" >{{item.value}}</option>           
    </select>
  </div>

</div>  
</template>
<script>
export default {
  name:'ElementColor',
  props: {
    data: Object
  },
  components: { 
  },
  data: function () {
    return { 
    }
  },
  watch: {}, 
  created(){ 
  },
  methods: { 
  }
}
</script>