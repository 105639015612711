<template>
<div>
   <div class="form-group " :class="[data.is_required == true ? 'required' : '']">
      <div class="row">
        <div class="col-sm-2">
          <label class="control-label" for="input-name">{{data.name}}</label>
        </div>
        <div class="col-sm-10">
          <div class="row">
 
        <b-form-checkbox-group id="checkbox-group" v-model="data.userinput" name="selectedUsers">
          <b-form-checkbox v-for="(item, index) in data.values" :value="item._id" :key="index" > 	&nbsp; {{item.value}}</b-form-checkbox>
        </b-form-checkbox-group>
   
 
          </div>
        </div>
      </div>
    </div>
</div>  
</template>
<script>
export default {
  name:'ElementCheckbox',
  props: {
    data: Object
  },
  components: { 
  },
  data: function () {
    return { 
    }
  },
  watch: {}, 
  created(){ 
  },
  methods: { 
  }
}
</script>