<template>
<div>
   <div class="form-group " :class="[data.is_required == true ? 'required' : '']">
      <div class="row">
        <div class="col-sm-2">
          <label class="control-label" for="input-name">{{data.name}}</label>
        </div>
        <div class="col-sm-10">
          <div class="row">
            <span v-for="(item,index) in data.values" :key="index" >
              <input class="image_radio" type="radio" v-model="data.userinput" :name="data.name" :value="item._id"> &nbsp; {{item.value}}  &nbsp;&nbsp;   		    
            </span>
          </div>
        </div>
      </div>
    </div>
</div>  
</template>
<script>
export default {
  name:'ElementRadio',
  props: {
    data: Object
  },
  components: { 
  },
  data: function () {
    return { 
    }
  },
  watch: {}, 
  created(){ 
    this.data.userinput = this.data.values[0]._id;
  },
  methods: { 
  }
}
</script>