<template>
<div>
  <!-- {{this.keydata}} -->
  <div :id="'key_'+item._id" v-for="(item,index) in this.keydata" :key="index">
       <component  :is="item.key_type" v-bind:editvalue="item.edituserinput" v-bind:data="item" ></component>
  </div> 
</div>  
</template>
<script>
import Textbox from '@/components/common/elements/ElementInput'
import Radio from '@/components/common/elements/ElementRadio'
import Checkbox from '@/components/common/elements/ElementCheckbox'
import TextAreaInput from '@/components/common/elements/ElementTextarea'
import Dropdown from '@/components/common/elements/ElementDropdown'
export default {
  name:'KeyinfoGen',
  props: {
    keydata: Array,
  },
  components: { 
    Textbox,
    Radio,
    Checkbox,
    TextAreaInput,
    Dropdown
  },
  data: function () {
    return { 
      keydatalist: []
    }
  },
  watch: {}, 
  created(){ 
    // this.keydata = this.keydatalist;
  },
  methods: { 
  }
}
</script>