<template>
<div>
  <div class="form-group " :class="[data.is_required == true || data.attribute_name   ? 'required' : '']" >
    <label class="control-label"><span>{{data.name}}</span><span>{{data.attribute_name}}</span></label>
    <select class="form-control select-arrow" @change="valueChange(true)" v-model="selected" >
      <option v-for="(item,index) in data.values" :key="index" :value="item._id" > {{item.value}}</option>                          
    </select>
  </div>

</div>  
</template>
<script>
export default {
  name:'ElementDropdown',
  props: {
    data: Object,
    editvalue: Object
  },
  components: { 
  },
  data: function () {
    return { 
      selected:""
    }
  },
  watch: {
    editvalue: function(params) {
      this.valueChange();
    }
  }, 
  created(){ 
    if(this.editvalue){
      this.selected = this.editvalue;
      this.valueChange();
    }
    if(this.selected === ""){
      this.selected = this.data.values[0]._id;
      this.valueChange();
    }     
  },
  methods: { 
    valueChange(user){ 
      if(!user){
        if(this.editvalue){
         this.selected =  this.editvalue._id;        
        } 
      }
       
      this.$set(this.data, 'userinput', this.selected);      
      // this.data.userinput = this.selected;
      
    }
  }
}
</script>

<style scoped>

.select-arrow {
  -webkit-appearance: menulist;
    box-sizing: border-box;
    align-items: center;
    white-space: pre;
    -webkit-rtl-ordering: logical;
    color:#555;
    background-color: white;
    cursor: default;
    border-width: 1px;
    border-style: solid;
    border-image: initial;
}

</style>
 
