<template>
<div> 
  <div class="form-group col-md-4" :class="[data.is_required == true ? 'required' : '']">
    <label class="control-label">{{data.attribute_name}}</label>
    <input class="form-control" v-model="data.userinput" type="number" > 
  </div>

</div>  
</template>
<script>
export default {
  name:'ElementSize',
  props: {
    data: Object
  },
  components: { 
  },
  data: function () {
    return { 
    }
  },
  watch: {}, 
  created(){ 
  },
  methods: { 
  }
}
</script>