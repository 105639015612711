<template>
<div> 
  <div class="row" :id="'sku_'+itemindex"> 
    <div v-for="(item,index) in keydata.skuattriarray" :key="index" class="col-sm-4">
        <ElementDropdown v-bind:editvalue="item.edituserinput" v-bind:data="item"  />
        <!-- <component  :is="item.attribute_name" v-bind:data="item" ></component> -->
    </div> 
    <div class="col-sm-4" style="margin-top: 30px;" v-if="itemindex != 0">
       <i @click="removeSku(itemindex)" class="fa fa-trash imgAdd" style="background:#b80000;"></i>
    </div>
  </div>   
  <div >
    <div class="row" v-if="keydata.skuattriarray.length > 0">
      <div class="col-sm-2 imgUp" style="padding-bottom:10px;" v-for="(item,index) in keydata.imgarray" :key="index">
        <div class="imagePreview">
          <img v-if="item === ''" src="https://res.cloudinary.com/sri-lanka-telecom-plc/image/upload/q_auto,f_auto/v1629962131/cochchi/mercahnt/1629962129939.webp" height="100%" />
          <img else :src="item" height="100%" />
        </div>
        <label class="btn btn-primary" 
        style="display: block; margin-top: -5px;">
          Upload<input type="button" @click="showProductImageModal(index, itemindex)" class="uploadFile img" value="Upload Photo" style="width: 0px;height: 0px;overflow: hidden; display: none;">
          <!-- Upload<input type="file" @change="onFileChange($event, index)" class="uploadFile img" value="Upload Photo" style="width: 0px;height: 0px;overflow: hidden; "> -->
        </label>
        <i style="color: red" @click="deleteImg(index)"  class="fa fa-trash del"></i>
      </div><!-- col-2 -->
      <i @click="addimage()" class="fa fa-plus imgAdd"></i>
    </div><!-- row -->
  </div><!-- container -->
          <br>
<!-- product image submit modal start-->
    <div
      class="modal fade"
      id="productImageModalCenter"
      tabindex="-1"
      role="dialog"
      aria-labelledby="productImageModalCenterTitle"
      aria-hidden="true"
    >
      <div
        class="modal-dialog modal-dialog-centered modal-md"
        role="document"
      >
        <div class="modal-content">
          <div class="modal-header">
            <!-- <button
              type="button"
              class="close"
              data-dismiss="modal"
              style="float: right;"
            >
              <span aria-hidden="false">&times;</span>
            </button> -->

            <div class="row align-contents">
              <h2 class="modal-title" style="margin-left: 20px;">Product Image
                <button style="float: right; margin-right: 20px;" type="button" class="close" data-dismiss="modal">
                  <span >&times;</span>
                </button>
              </h2>
            </div>
          </div>

          <div class="modal-body">

            <!-- <div class="row text-center text-lg-left" style="height: 400px; overflow-y: scroll;">
                <div class="col-lg-3 col-md-3 col-xs-12" style="margin-bottom: 40px;" v-for="item in galleryImages" :key="item._id">
                    <a class="d-block mb-4 h-100">
                        <img @click="haddleAddToImage(item.image)" class="img-fluid img-thumbnail galaryImg" :src='item.image'>
                    </a>
                </div>
            </div> -->

            <div v-if="galleryImages.length !== 0" id="imageContainer" class="row text-center text-lg-left" style="height: 400px; overflow-y: scroll;">
            <div class="col-sm-3 imgUp" style="padding-bottom:10px;" v-for="item in galleryImages" :key="item._id">
              <div class="imagePreviewM0dal galaryImg">
                <img :src="item.image" height="100%" @click="haddleAddToImage(item.image)" />
              </div>
            </div>
           </div>

           <div class="row text-center text-lg-left" v-if="galleryImages.length === 0" style="padding-bottom:10px;">
              <p style="color: red;">Please upload images using Gallery.</p>
           </div>

            <div class="row">
              <form action="#" method="post" enctype="multipart/form-data">
                <div class="col-sm-12">
                  <input v-on:keydown.enter.prevent="removrHashIndex" type="text" placeholder="image url" class="form-control" v-model="imageUrl" />
                </div>
              </form>
            </div>

          </div>
          <div class="modal-footer">
            <div>
              <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
              <button
                type="button"
                class="btn merchprof-sbtn"
                @click="onFileChange()"
                :disabled="!isValid"
              >Save</button>
            </div>
          </div>
        </div>
      </div>
  </div>
<!-- product image submit modal end-->  
</div>  
</template>
<script>
import ElementDropdown from '@/components/common/elements/ElementDropdown' 
import Color from '@/components/common/elements/ElementColor' 
import Size from '@/components/common/elements/ElementSize' 
import mixinGalary from "@/mixins/merchant/APIGalary";

import mixinProduct from "@/mixins/merchant/APIProduct";

export default {
  name:'SkuGen',
  props: {
    keydata: Object,
    keyArray: Array,
    itemindex: Number
  },
  components: { 
    Color, 
    Size,
    ElementDropdown
  },
  mixins: [mixinProduct, mixinGalary],
  data: function () {
    return { 
      keydatalist: [],
      fileUploadFormData: new FormData(),

      imageUrl: "",
      imageIndex: 0,

      galleryImages: [],

      newIndex: 0
    }
  },
  watch: {}, 
  computed: {
    isValid() {
      return (
        this.imageUrl
      )
    }
  },
  created(){ 
    this.haddleImgGalaryGet();
    // this.keydata = this.keydatalist;
  },
  methods: { 
    addimage() {
      if(this.keydata.imgarray.length === 3){
        this.$swal.fire({
                    backdrop:false,
                    position: 'center',
                    type: 'error',
                    title: 'Only Three Images Allowed Per SKU',
                    showConfirmButton: false,
                    timer: 1500
                  });
        return;
      }
      this.keydata.imgarray.push("");
    },
    showProductImageModal: function(index, itemInd){
      this.imageIndex = index;

      localStorage.setItem("sku_main_id", itemInd);
      localStorage.setItem("sku_image_id", index);
      
      this.haddleImgGalaryGet();
      this.imageUrl = "";
      $("#productImageModalCenter").modal("show");
    },
    haddleImgGalaryGet: async function() {
      try {
          let response = await this.getGalleryList();
          this.galleryImages = response;
      } catch (error) {
          this.$swal.fire({
          position: "center",
          type: "error",
          title: error.data.message,
          showConfirmButton: false,
          timer: 1500,
          });
      }
    },
    onFileChange() {
      this.createImage(this.imageUrl);
      $("#productImageModalCenter").modal("hide");
      this.imageUrl = "";
    },
    createImage: async function(file){    
      try {
        this.keyArray[localStorage.getItem("sku_main_id")].imgarray.splice(localStorage.getItem("sku_image_id"), 1, file);
        this.$parent.validateImageCount();
        this.fileUploadFormData = new FormData();     
      } catch (error) {
          console.log(error);
      } 
    },
    deleteImg(index) {
      this.keydata.imgarray.splice(index, 1);
      if(this.keydata.imgarray.length === 0){
        this.keydata.imgarray.push("");
      }
      this.$parent.validateImageCount();
    },
    removeSku(itemindex) {
      this.$parent.removeSkuByIndex(itemindex); 
      this.$parent.validateImageCount();
    },
    haddleAddToImage: function(image) {
      this.imageUrl = image;
      var btnContainer = document.getElementById("imageContainer");

      // Get all buttons with class="btn" inside the container
      var btns = btnContainer.getElementsByClassName("galaryImg");

      // Loop through the buttons and add the active class to the current/clicked button
      for (var i = 0; i < btns.length; i++) {
        btns[i].addEventListener("click", function() {
          var current = document.getElementsByClassName("active");
          current[0].className = current[0].className.replace(" active", "");
          this.className += " active";
        });
      } 
    },
    removrHashIndex: function(e) {
      if(e) e.preventDefault();
    }
  }
}
</script>
<style scoped>
.imagePreview {
  width: 100%;
  height: 180px;
  background-position: center center;
  background-color:#fff;
  background-size: cover;
  background-repeat:no-repeat;
  display: inline-block;
  box-shadow:0px -3px 6px 2px rgba(0,0,0,0.2);
}
.del
{
  position:absolute;
  top:0px;
  right:15px;
  width:30px;
  height:30px;
  text-align:center;
  line-height:30px;
  background-color:rgba(255,255,255,0.6);
  cursor:pointer;
}
.imgAdd
{
  width:30px;
  height:30px;
  border-radius:50%;
  background-color:#4bd7ef;
  color:#fff;
  box-shadow:0px 0px 2px 1px rgba(0,0,0,0.2);
  text-align:center;
  line-height:30px;
  margin-top:0px;
  cursor:pointer;
  font-size:15px;
}

.galaryImg {
  width: 100%;
  height: 140px;
}

.active, .galaryImg:hover {
  width: 100%;
  cursor: pointer;
  height: 140px;
  border: 5px solid #666666;
}


.imagePreviewM0dal {
  width: 100%;
  height: 140px;
  background-position: center center;
  background-color:#fff;
  background-size: cover;
  background-repeat:no-repeat;
  display: inline-block;
  box-shadow:0px -3px 6px 2px rgba(0,0,0,0.2);
}
</style>