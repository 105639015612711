<template>
<div>
  <div class="well merprdcat" style="height: 320px; overflow: auto;" v-if="data.length > 0">
    <!-- <ul id="cat_accordion" class="list-group text-left"> -->
      <div style="padding-left: 2px;" :id="item._id" :class="{ cactive : active_el == item._id }" @click="levelClick(level, item._id, item.have_child, item)" v-for="(item, index) in data" :key="index"><a class="cutom-parent">{{item.category_name}} <i class="fa fa-chevron-right" style="font-size: smaller;" v-if="item.have_child"></i></a><!----></div>                          
    <!-- </ul> -->
  </div> 
</div>  
</template>
<script>

import mixinCategory from "@/mixins/merchant/APICategory";

export default {
  name:'ProductCategorySelect',
  mixins: [mixinCategory],
  props: {
    data: Array,
    level: Number,
    maincid: String
  },
  components: {  
  },
  data: function () {
    return { 
      keydatalist: [
        {
          type: 'Textbox',
          title: 'Test Key Info',
          is_required: true,
          maxlength: 5,
          userinput: ''
        },
        {
          type: 'Radio',
          title: 'Test Key Info',
          _id: '12122131d32dd',
          is_required: false,
          values: [{
            title: 'Yes',
            _id: '1sadaasdaw'
          },{
            title: 'No',
            _id: 'skdkfsaasdaw'
          }],
          userinput: ''
        },
        {
          type: 'Checkbox',
          title: 'Test Checkbox',
          _id: '1212asdasc22131d32dd',
          is_required: false,
          values: [{
            title: 'Yes',
            _id: '1sadaasdaw'
          },{
            title: 'No',
            _id: 'skdkfsaasdaw'
          }],
          userinput: []
        },
        {
          type: 'Textarea',
          title: 'Test Textarea',
          is_required: true,
          maxlength: 150,
          userinput: ''
        },
      ],
      active_el:0
    }
  },
  watch: {}, 
  created(){ 
    //this.checkMainOrSub();
  },
  methods: { 
    levelClick(level, catid, have_child, item){ 
       this.active_el = catid;
       this.$parent.updateMenuLevel(level, catid, have_child); 
       if (level === 0) {
         this.$parent.updateDiscount(item); 
       }
    },
    checkMainOrSub(){
      if(this.level > 0){
        this.data = [];
      } else {
        this.data = this.getSubCategories();
      }
    },
    getSubCategories: async function(id){
      try {
        let subcat = await this.getSubCategoryById(id);       
      } catch (error) {
          console.log(error);
      } 
    },
  }
}
</script>
<style>
.merprdcat a:hover{
  color: #5779ae;
}
</style>
