<template>
<div>
  <div class="form-group " :class="[data.is_required == true ? 'required' : '']">
    <label class="control-label">{{data.name}}  <small v-if="data.maxlength" class="text-muted"> - Max Length : {{data.maxlength}}</small>  </label>
    <textarea class="form-control" v-model="data.userinput" rows="5" :maxlength="data.maxlength" :required = data.is_required></textarea>
  </div>
</div>  
</template>
<script>
export default {
  name:'ElementTextarea',
  props: {
    data: Object
  },
  components: { 
  },
  data: function () {
    return { 
    }
  },
  watch: {}, 
  created(){ 
  },
  methods: { 
  }
}
</script>