<template>
<div>
  <div class="form-group " :class="[data.is_required == true ? 'required' : '']">
    <label class="control-label">{{data.name}}</label>
    <input type="text" v-model="data.userinput" :maxlength="data.maxlength" :placeholder="'Enter ' + data.name" :required = data.is_required class="form-control"/>
  </div>
</div>  
</template>
<script>
export default {
  name:'ElementInput',
  props: {
    data: Object
  },
  components: { 
  },
  data: function () {
    return { 
    }
  },
  watch: {}, 
  created(){ 
  },
  methods: { 
  }
}
</script>